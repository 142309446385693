import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "../app/shared/Spinner";
import AdminRoutes from "./routes/admin-routes";
import AuthRoute from "./routes/auth-routes";
import routes from "./routes/route";
import { getLoggedIn } from "./service/http-service";

class AppRoutes extends Component {

  validLayouts = [
    "/admin", "/finance", "/passportUser",
    "/etcUser", "/coordinatorUser", "/executiveUser"
  ];

  getRoutes = (routes) => {
    const r  = routes.map((prop, key) => {
      if (this.validLayouts.indexOf(prop.layout) > -1) {
        return (
          <Route
            path={prop.path}
            render={(prop) => getLoggedIn() ? <AdminRoutes {...prop} /> :
            <Redirect from="/" to="/auth/login" />}
            key={key}
          />
        )
      } else {
        return null
      }
    });
    return r;
  }

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          {this.getRoutes(routes)}
          <Route path="*" render={(props) => <AuthRoute {...props} />} />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
