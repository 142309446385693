import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import routes from "../routes/route";
import { getUserName, getLoginType, getLoginTypeId } from '../service/http-service';

class Sidebar extends Component {
  state = {};
  userName = getUserName() ? getUserName() : '';
  userType = getLoginType() ? getLoginType() : '';
  userTypeId = getLoginTypeId() ? getLoginTypeId() : '';

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" }
    ];

    dropdownPaths.forEach(obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  getValidRoute(props) {
    const matrix = {
      '1': '/admin',
      '2': '/executiveUser',
      '3': '/finance',
      '4': '/passportUser',
      '5': '/etcUser',
      '6': '/coordinatorUser'
    };

    const route = matrix[this.userTypeId];
    if (!route) {
      return false;
    } else if (props.layout == route) {
      return true;
    } else if (
      props.allow &&
      props.allow.length > 0 &&
      props.allow.indexOf(route) > -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  createLink() {
    let ROUTES = routes.map((prop, key) => {
      if (prop.hideFromMenu) {
        return null;
      } else if (prop.common == true) {
        return <li
          className={
            this.isPathActive(prop.path) ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to={prop.path}>
            <span className="menu-title">
              <Trans>{prop.name}</Trans>
            </span>
            <i className={prop.icon}></i>
          </Link>
        </li>
      } else if (this.getValidRoute(prop)) {
        return <li
          className={
            this.isPathActive(prop.path) ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to={prop.path}>
            <span className="menu-title">
              <Trans>{prop.name}</Trans>
            </span>
            <i className={prop.icon}></i>
          </Link>
        </li>
      } else {
        return null;
      }
      // else if (this.userType == 'Admin') {
      //   if (
      //     prop.layout == '/admin') {
      //     return <li
      //       className={
      //         this.isPathActive(prop.path) ? "nav-item active" : "nav-item"
      //       }
      //     >
      //       <Link className="nav-link" to={prop.path}>
      //         <span className="menu-title">
      //           <Trans>{prop.name}</Trans>
      //         </span>
      //         <i className={prop.icon}></i>
      //       </Link>
      //     </li>
      //   }
      // } else if (this.userType == 'Finance User') {
      //   if (prop.layout == '/finance') {
      //     return <li
      //       className={
      //         this.isPathActive(prop.path) ? "nav-item active" : "nav-item"
      //       }
      //     >
      //       <Link className="nav-link" to={prop.path}>
      //         <span className="menu-title">
      //           <Trans>{prop.name}</Trans>
      //         </span>
      //         <i className={prop.icon}></i>
      //       </Link>
      //     </li>
      //   }
      // } 
      // else {
      //   return null;
      // }
    });

    return ROUTES;
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item navbar-brand-mini-wrapper">
            <Link
              className="nav-link navbar-brand brand-logo-mini"
              to="/dashboard"
            >
              <img
                src={require("../../assets/images/logo-mini.svg")}
                alt="logo"
              />
            </Link>
          </li>
          <li className="nav-item nav-profile">
            <a
              href="!#"
              className="nav-link"
              onClick={evt => evt.preventDefault()}
            >
              <div className="profile-image">
                {this.userType == "Admin" ?
                  <img
                    className="img-xs rounded-circle"
                    src={require("../../assets/images/Mahmoud.jpg")}
                    alt="profile"
                  /> :
                  <img
                    className="img-xs rounded-circle"
                    src={require("../../assets/images/blank-profile.png")}
                    alt="profile"
                  />}
                <div className="dot-indicator bg-success"></div>
              </div>
              <div className="text-wrapper">
                <p className="profile-name">{this.userName}</p>
                <p className="designation">
                  <Trans>{this.userType}</Trans>
                </p>
              </div>
              {/* <div className="icon-container">
                <i className="icon-bubbles"></i>
                <div className="dot-indicator bg-danger"></div>
              </div> */}
            </a>
          </li>
          {this.createLink()}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    console.log(this.props.location.pathname, path);
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach(el => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
          // if (el.querySelector('.ReactCollapse--collapse')) {
          //   el.querySelector('.ReactCollapse--collapse').setAttribute('style', 'height: auto; overflow: initial;');
          // }
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
          // if (el.querySelector('.ReactCollapse--collapse')) {
          //   el.querySelector('.ReactCollapse--collapse').setAttribute('style', 'height: 0; overflow: hidden;');
          // }
        }
      });
    });
  }
}

export default withRouter(Sidebar);
