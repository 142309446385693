import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { withTranslation } from "react-i18next";
import routes from "./routes/route";

class App extends PureComponent {
  state = {}
  // history = useHistory();

  componentDidMount() {
    this.onRouteChanged();
  }

  gotoApplyService = (e) => {
    e.preventDefault();
    const { history } = this.props;
    if (history) history.push('/apply-service');
  }

  gotoMakePayment = (e) => {
    e.preventDefault();
    const { history } = this.props;
    if (history) history.push('/make-payment');
  }

  gotoTracking = (e) => {
    e.preventDefault();
    const { history } = this.props;
    if (history) history.push('/track-application');
  }

  gotoLogin = (e) => {
    e.preventDefault();
    const { history } = this.props;
    if (history) history.push('/login');
  }

  checkActivePath = (path) => {
    if (
      this.props.location && 
      this.props.location.pathname
    ) {
      return this.props.location.pathname.startsWith(path);
    } else {
      return false;
    }
  }

  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : '';
    return (
      <div className="container-scroller">
        {navbarComponent}
        <div className="container-fluid page-body-wrapper">
          {sidebarComponent}
          <div className="main-panel">
            <div className=
              {!navbarComponent && !sidebarComponent ? "content-wrapper backgoud-img" : "content-wrapper"}>
              {!navbarComponent && !sidebarComponent ?
                <div className='row'>
                  <div className='text-left col-lg-2'>
                    <img 
                      src={require("../../src/assets/images/project_logo.png")} 
                      alt="logo"
                      className='logo-cls'
                     />
                  </div>
                  <div className='text-right pt-3 pr-2 col-lg-10'>
                    <a href="!#"
                      onClick={this.gotoLogin}
                      className={
                        this.checkActivePath('/login') ? "auth-link text-blue pb-2" : "auth-link text-black pb-2"
                      }>
                      <i className="mdi mdi-account-circle"></i>
                      &nbsp;
                      <span className='auth-label'>Login</span>
                    </a>

                    &nbsp; &nbsp; &nbsp;
                    <a href="!#"
                      onClick={this.gotoApplyService}
                      className={
                        this.checkActivePath('/apply-service') ? "auth-link text-blue pb-2" : "auth-link text-black pb-2"
                      }>
                      <i className='mdi mdi-book-open'></i>
                      &nbsp;
                      <span className='auth-label'>Apply for Service</span>
                    </a>
                    &nbsp; &nbsp;
                    <a href="!#"
                      onClick={this.gotoMakePayment}
                      className={
                        this.checkActivePath('/make-payment') ? "auth-link text-blue pb-2" : "auth-link text-black pb-2"
                      }>
                      <i className='mdi mdi-credit-card'></i>
                      &nbsp;
                      <span className='auth-label'>Make Payment</span>
                      &nbsp;&nbsp;
                    </a>
                    <a href="!#"
                      onClick={this.gotoTracking}
                      className={
                        this.checkActivePath('/track-application') ? "auth-link text-blue pb-2" : "auth-link text-black pb-2"
                      }>
                      <i className='mdi mdi-arrange-bring-forward'></i>
                      &nbsp;
                      <span className='auth-label'>Track Application </span>
                      &nbsp;&nbsp;
                    </a>
                  </div>

                  <div className='col-12'>
                    <AppRoutes />
                  </div>

                </div>
                : <AppRoutes />
              }
              {/* <AppRoutes /> */}
            </div>
            {footerComponent}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    // const { i18n } = this.props;
    // if (this.props.location.pathname === '/rtl') {
    //   document.querySelector('body').classList.add('rtl');
    //   i18n.changeLanguage('ar');
    // } else {
    //   document.querySelector('body').classList.remove('rtl');
    //   i18n.changeLanguage('en');
    // }
    // const fullPageLayoutRoutes = [
    //   '/login', '/forgot-password', '/apply-service',
    //   '/show-msg'
    // ];
    const fullPageLayoutRoutes = routes.filter(r => r.layout == '/auth');
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i].path) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}

export default withTranslation()(withRouter(App));
